import { useTableOptions } from '@/composables'
import { appointmentService } from '@/modules/product/services'
import { computed, ref, watch } from '@vue/composition-api'

const cached = []

export default (query = {}) => {
  const applicationListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'number' },
    { text: 'Participnat', value: 'participant', sortable: false },
    { text: 'Contact', value: 'participant_contact', sortable: false },
    { text: 'Account', value: 'user_id' },
    { text: 'Location', value: 'destination_id' },
    { text: 'Service', value: 'rechargeable_id' },
    { text: 'Service Datetime', value: 'inventory_id' },
    { text: 'Complete at', value: 'completes_at' },
    { text: 'Status', value: 'status' },
    { text: 'Action', value: 'actions', sortable: false },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['number'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const statusInFilter = ref(cached[2] || ['completed', 'rejected', 'refunded'])
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  const applicationOptions = computed(() =>
    applicationListTable.value.map(application => ({
      title: application.name.en,
      value: application.id,
    })),
  )

  const mapStatus = status => {
    const mappings = {
      rejected: 'CANCELLED',
      accepted: 'CONFIRMED',
    }

    return mappings[status] || status.toUpperCase()
  }

  const statusOptions = [
    { text: 'Completed', value: 'completed' },
    { text: 'CANCELLED', value: 'rejected' },
    { text: 'REFUNDED', value: 'refunded' },
  ]

  // fetch data
  const loadApplications = () =>
    appointmentService
      .index(
        useTableOptions(options.value, {
          search: searchQuery.value,
          status_in: statusInFilter.value,
          ...query,
        }),
      )
      .then(({ records, pagination }) => {
        applicationListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    selectedRows.value = []
    loadApplications()
    cache()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    applicationListTable,
    applicationOptions,
    tableColumns,
    searchQuery,
    statusInFilter,
    statusOptions,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadApplications,
    mapStatus,
  }
}
